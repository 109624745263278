import React, { useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import ScrollSection from '@components/ScrollSection'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import Container from '@components/Container'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateImage from '@components/animation/AnimateImage'
import { animation } from '@styles/vars/animation.style'
import Spacer from '@components/Spacer'
import SectionTitle from '@components/SectionTitle'
import { colors } from '@styles/vars/colors.style'
import {
  OverviewMain,
  OverviewText,
  OverviewImages,
  OverviewImage,
  OverviewLine,
  OverviewContentWrapper,
} from './index.style'
import {
  Heading1,
  Heading2,
  TextBody,
  TextBodyLarge,
} from '@components/TextStyles'
import { GatsbyImage } from 'gatsby-plugin-image'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import { useStore, setHeader } from '@Store/'

gsap.registerPlugin(ScrollTrigger)

const OverviewContent = ({ image, title, subtitle, text }) => {
  const [ref, inView] = useInView({
    rootMargin: `0px 0px -30% 0px`,
    triggerOnce: true,
  })

  return (
    <OverviewText>
      <AnimateImage delay={0.5} size={`large`}>
        <OverviewImage>
          <GatsbyImage
            image={image.gatsbyImageData}
            alt={image.description || title}
            objectFit="cover"
            style={{ width: `100%`, height: `100%` }}
          />
        </OverviewImage>
      </AnimateImage>
      <Heading2 as={`h2`}>
        <AnimateSplitText type={`lines,chars`}>{title}</AnimateSplitText>
      </Heading2>
      {subtitle && (
        <>
          <Spacer size={[8, 16]} />
          <TextBodyLarge color={colors.orange}>
            <AnimateSplitText>{subtitle}</AnimateSplitText>
          </TextBodyLarge>
        </>
      )}
      <Spacer size={[28, 35]} />
      <TextBody>
        <AnimateSplitText delay={animation.textDelay}>{text}</AnimateSplitText>
      </TextBody>
      <OverviewLine ref={ref} show={inView} />
    </OverviewText>
  )
}

const Overview = ({ sectionTitle, heading, sections }) => {
  const [, dispatch] = useStore()
  const wrap = useRef()
  const images = useRef()

  const [ref, inView] = useInView({
    rootMargin: '0px 0px -95% 0px',
    triggerOnce: false,
  })

  useEffect(() => {
    setHeader(dispatch, inView ? `light` : `dark`)
  }, [dispatch, inView])

  const [active, setActive] = useState(0)

  useEffect(() => {
    let mm = gsap.matchMedia()

    mm.add('(min-width: 1024px)', () => {
      ScrollTrigger.create({
        trigger: wrap.current,
        start: 'top top',
        end: 'bottom bottom',
        pin: images.current,
        onUpdate: self => {
          setActive(Math.round(self.progress * (sections.length - 1)))
        },
      })
    })
  }, [sections])

  return (
    <ScrollSection>
      <OverviewMain ref={ref}>
        <Spacer size={[105, 160]} />
        <Container>
          <Heading1 as="h2">
            <AnimateSplitText type={`lines,chars`}>{heading}</AnimateSplitText>
          </Heading1>
          <Spacer size={[64, 180]} />
          <div ref={wrap}>
            <Grid>
              <GridItem tabletL={5} tabletLAlign={`flex-start`}>
                <OverviewImages ref={images}>
                  <AnimateImage delay={0.5} size={`large`}>
                    {React.Children.toArray(
                      sections.map((section, index) => {
                        return (
                          <OverviewImage show={index === active}>
                            <GatsbyImage
                              image={section.image.gatsbyImageData}
                              alt={section.image.description || section.title}
                              objectFit="cover"
                              style={{ width: `100%`, height: `100%` }}
                            />
                          </OverviewImage>
                        )
                      })
                    )}
                  </AnimateImage>
                </OverviewImages>
              </GridItem>
              <GridItem tabletL={5} tabletLStart={7}>
                <OverviewContentWrapper>
                  <SectionTitle title={sectionTitle} />
                  {React.Children.toArray(
                    sections.map(section => <OverviewContent {...section} />)
                  )}
                </OverviewContentWrapper>
              </GridItem>
            </Grid>
          </div>
        </Container>
        <Spacer size={[64, 160]} />
      </OverviewMain>
    </ScrollSection>
  )
}

Overview.propTypes = {
  heading: PropTypes.string,
  sections: PropTypes.array,
}

export default Overview
