import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const Plus = ({
  width = 16,
  height = 16,
  fill = colors.dark,
  responsive = false,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
      focusable="false"
      role="img"
      {...svgAttributes}
    >
      <path
        d="M7.99805 16L7.99805 0"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
      <path
        d="M0 7.99805L16 7.99805"
        stroke={fill}
        strokeWidth="1.5"
        strokeMiterlimit="10"
      />
    </svg>
  )
}

export default Plus
