import styled from 'styled-components'
import { mq } from '@styles/vars/media-queries.style'
import { TextBodySmall } from '@styles/vars/textStyles.style'
import { colors } from '@styles/vars/colors.style'

export const SectionTitleWrapper = styled.div`
  position: relative;
  margin-bottom: 2.4rem;
  padding-left: 2.4rem;
  color: currentColor;

  &:before {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    display: block;
    width: 0.8rem;
    height: 0.8rem;
    background-color: ${colors.orange};
    border-radius: 50%;
    opacity: ${({ show }) => (show ? 1 : 0)};
    transform: translateY(-50%);
    transition: opacity 0.6s;

    ${mq.tabletL} {
      transform: translate(-100%, -50%);
    }
  }

  ${TextBodySmall} {
    text-transform: uppercase;
  }

  ${mq.tabletL} {
    position: absolute;
    top: 0;
    left: 0;
  }
`
