import styled from 'styled-components'
import { easings } from '@styles/vars/easings.style'

export const HrMain = styled.div`
  background-color: ${props => props.bg};
  bottom: 0;
  height: 0.1rem;
  left: 0;
  opacity: ${props => (props.show ? 1 : 0)};
  position: absolute;
  right: 0;
  transform: scaleX(${props => (props.show ? 1 : 0.6)});
  transform-origin: 0% 0%;
  transition: opacity 1s ${easings.inOut.default},
    transform 1s ${easings.inOut.default};
  transition-delay: ${props => props.delay}s;
`
