import styled from 'styled-components'
import { mq } from '@styles/vars/media-queries.style'
import { colors } from '@styles/vars/colors.style'
import { clamp } from '@styles/utils/conversion.style'
import { easings } from '@styles/vars/easings.style'
import { Heading4, TextBodySmall } from '@styles/vars/textStyles.style'
import { AccordionContent } from '@components/Accordion/index.style'
import { ButtonMain } from '@components/Button/index.style'

export const ReleaseNotesMain = styled.div`
  background-color: ${colors.light};
  height: 100svh;
  position: relative;
  width: 100svw;

  ${mq.tabletL} {
    border-radius: 2.4rem;
    height: 70svh;
    max-height: 88rem;
    width: 75rem;
  }
`

export const ReleaseNotesClose = styled.div`
  cursor: pointer;
  ${clamp('left', 24, 48)};
  position: absolute;
  top: 2.4rem;
  z-index: 15;

  ${ButtonMain} {
    ${mq.tabletL} {
      display: none;
    }
  }

  svg {
    ${mq.tabletLMax} {
      display: none;
    }
  }

  ${mq.tabletL} {
    left: auto;
    right: 1.6rem;
    top: 1.6rem;
    transform: rotateZ(45deg);

    svg {
      display: block;
    }
  }
`

export const ReleaseNotesInner = styled.div`
  ${clamp('padding', 24, 48)};
  padding-right: 7.2rem;

  ${mq.tabletLMax} {
    padding-right: 4.8rem;
    padding-top: 9.2rem;
  }
`

export const ReleaseNotesText = styled.div`
  ${clamp('padding-bottom', 16, 24)};
  margin-bottom: 3.2rem;
  position: relative;

  ${TextBodySmall} {
    color: ${colors.darkgrey};
    max-width: 900px;
    width: 90%;
  }

  ${AccordionContent} ${TextBodySmall} {
    padding-top: 2.4rem;
  }

  ${ButtonMain} {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  .split__text {
    transition-delay: ${props => !props.show && `0s`};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const ReleaseNotesTitle = styled.div`
  align-items: center;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-top: 1.6rem;

  ${Heading4},
  ${TextBodySmall} {
    white-space: nowrap;
  }

  ${Heading4} {
    ${clamp('min-width', 80, 120)};
  }

  svg {
    transform: rotateZ(${props => (props.show ? 135 : 0)}deg);
    transition: transform 1s ${easings.inOut.default};
  }
`
