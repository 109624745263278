import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { useInView } from 'react-intersection-observer'
import ScrollSection from '@components/ScrollSection'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import Container from '@components/Container'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateImage from '@components/animation/AnimateImage'
import { animation } from '@styles/vars/animation.style'
import Spacer from '@components/Spacer'
import {
  HowItWorksMain,
  HowItWorksSectionTitle,
  HowItWorksText,
  HowItWorksVideo,
} from './index.style'
import { Heading2, TextBody } from '@components/TextStyles'
import obdVideo from '@components/Carousel/video/obd.mp4'
import SectionTitle from '@components/SectionTitle'

const HowItWorks = ({ sectionTitle, heading, text }) => {
  const [ref, inView] = useInView({
    rootMargin: animation.rootMargin,
    triggerOnce: false,
  })

  const video = useRef()

  useEffect(() => {
    if (inView) {
      video.current
        .play()
        .then(() => {})
        .catch(error => {
          console.log(error)
          video.current.setAttribute('controls', 'controls')
        })
    } else {
      video.current.pause()
    }
  }, [inView])

  return (
    <ScrollSection>
      <HowItWorksMain ref={ref}>
        <Spacer size={[64, 160]} />
        <Container>
          <HowItWorksSectionTitle>
            <SectionTitle title={sectionTitle} />
          </HowItWorksSectionTitle>
          <Grid>
            <GridItem tabletL={6} tabletLStart={7} tabletLAlign={`center`}>
              <HowItWorksVideo>
                <AnimateImage delay={0.5} size={`large`}>
                  <video
                    ref={video}
                    src={obdVideo}
                    playsInline
                    loop
                    muted
                    preload="auto"
                  />
                </AnimateImage>
              </HowItWorksVideo>
            </GridItem>
            <GridItem
              tabletL={5}
              tabletLAlign={`flex-end`}
              tabletLOrder={-1}
              desk={4}
            >
              <HowItWorksText>
                <Heading2 as={`h2`}>
                  <AnimateSplitText type={`lines,chars`}>
                    {heading}
                  </AnimateSplitText>
                </Heading2>
                <Spacer size={[28, 35]} />
                <TextBody>
                  <AnimateSplitText delay={animation.textDelay}>
                    {text}
                  </AnimateSplitText>
                </TextBody>
              </HowItWorksText>
            </GridItem>
          </Grid>
        </Container>
        <Spacer size={[64, 160]} />
      </HowItWorksMain>
    </ScrollSection>
  )
}

HowItWorks.propTypes = {
  heading: PropTypes.string,
  text: PropTypes.string,
}

export default HowItWorks
