import styled from 'styled-components'
import { mq } from '@styles/vars/media-queries.style'
import { colors } from '@styles/vars/colors.style'
import { clamp } from '@styles/utils/conversion.style'
import { easings } from '@styles/vars/easings.style'
import { Heading1, TextBody } from '@styles/vars/textStyles.style'
import {
  AnimateImageMain,
  AnimateImagePosition,
} from '@components/animation/AnimateImage/index.style'

export const OverviewMain = styled.div`
  background-color: ${colors.darkergrey};
  color: ${colors.light};

  ${Heading1} {
    ${clamp('font-size', 36, 92)}

    ${mq.tabletL} {
      text-align: center;
    }
  }
`

export const OverviewLine = styled.div`
  background-color: ${colors.darkgrey};
  bottom: 0;
  content: '';
  display: none;
  height: 0.1rem;
  left: 0;
  opacity: ${props => (props.show ? 1 : 0)};
  position: absolute;
  right: 0;
  transform: scaleX(${props => (props.show ? 1 : 0.6)});
  transform-origin: 0% 0%;
  transition: opacity 1s ${easings.inOut.default},
    transform 1s ${easings.inOut.default};

  ${mq.tabletL} {
    display: block;
  }
`

export const OverviewImages = styled.div`
  position: relative;
  display: none;

  ${mq.tabletL} {
    height: 100vh;
    display: flex;
    align-items: center;
  }

  ${AnimateImageMain},
  ${AnimateImagePosition} {
    width: 100%;
  }

  ${AnimateImagePosition} {
    display: grid;
    border-radius: 1.6rem;
    overflow: hidden;
  }
`

export const OverviewImage = styled.div`
  ${mq.tabletL} {
    grid-area: 1 / 1;
    opacity: ${({ show }) => (show ? 1 : 0)};
    transform: scale(${props => (props.show ? 1.01 : 1.2)});
    transition: ${props =>
      props.show
        ? `opacity 2s ${easings.out.default}, transform 3s ${easings.out.default}`
        : `opacity .3s ${easings.in.default}, transform 0s .5s`};
  }
`

export const OverviewText = styled.div`
  padding-bottom: 7.2rem;
  position: relative;

  &:last-child {
    padding-bottom: 0;
  }

  ${mq.tabletL} {
    padding-bottom: 30vh;
    padding-top: 30vh;

    &:first-child {
      padding-top: 40vh;
    }

    &:last-child {
      padding-bottom: 40vh;
    }

    ${OverviewImage} {
      display: none;
    }
  }

  ${TextBody} {
    color: ${colors.midgrey};
    max-width: 95%;
  }

  &:last-child ${OverviewLine} {
    display: none;
  }

  ${AnimateImagePosition} {
    border-radius: 1.6rem;
    overflow: hidden;
    margin-bottom: 3.2rem;

    img {
      max-height: 500px;
    }
  }
`

export const OverviewContentWrapper = styled.div`
  position: relative;
`
