import React, { useState } from 'react'
import PropTypes from 'prop-types'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import { animation } from '@styles/vars/animation.style'
import Spacer from '@components/Spacer'
import {
  ReleaseNotesClose,
  ReleaseNotesInner,
  ReleaseNotesMain,
  ReleaseNotesText,
  ReleaseNotesTitle,
} from './index.style'
import { Heading3, Heading4, TextBodySmall } from '@components/TextStyles'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Accordion from '@components/Accordion'
import Hr from '@components/Hr'
import { colors } from '@styles/vars/colors.style'
import Plus from '@components/svgs/Plus'
import SimpleBar from 'simplebar-react'
import 'simplebar-react/dist/simplebar.min.css'
import RichText from '@components/RichText'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import Button from '@components/Button'

gsap.registerPlugin(ScrollTrigger)

const ReleaseNotesContent = ({ date, version, text, open, openEvent }) => {
  return (
    <ReleaseNotesText show={open}>
      <ReleaseNotesTitle onClick={openEvent} show={open}>
        <Heading4>
          <AnimateSplitText delay={animation.textDelay}>
            {date}
          </AnimateSplitText>
        </Heading4>
        <TextBodySmall>
          <AnimateSplitText delay={animation.textDelay}>
            {version}
          </AnimateSplitText>
        </TextBodySmall>
        <AnimateSlideIn>
          <Plus />
        </AnimateSlideIn>
      </ReleaseNotesTitle>

      <Accordion open={open}>
        {text && <RichText content={text} delay={0.2} animate={open} />}
      </Accordion>

      <Hr bg={colors.lightgrey} />
    </ReleaseNotesText>
  )
}

const ReleaseNotes = ({ releaseNotes, close }) => {
  const [activeAccordion, setActiveAccordion] = useState(0)

  return (
    <ReleaseNotesMain>
      <ReleaseNotesClose onClick={close}>
        <AnimateSlideIn>
          <Button variant={'secondary'} size={'small'}>
            Close
          </Button>
          <Plus />
        </AnimateSlideIn>
      </ReleaseNotesClose>
      <SimpleBar>
        <ReleaseNotesInner>
          <Heading3>
            <AnimateSplitText type={`lines,chars`}>
              Release Notes
            </AnimateSplitText>
          </Heading3>
          <Spacer size={[24, 48]} />
          {React.Children.toArray(
            releaseNotes.map((note, index) => (
              <ReleaseNotesContent
                {...note}
                open={index === activeAccordion}
                openEvent={() => setActiveAccordion(index)}
              />
            ))
          )}
        </ReleaseNotesInner>
      </SimpleBar>
    </ReleaseNotesMain>
  )
}

ReleaseNotes.propTypes = {
  releaseNotes: PropTypes.array,
}

export default ReleaseNotes
