import React, { Suspense, lazy } from 'react'
const Spline = lazy(() => import('@splinetool/react-spline'))

const Model = props => {
  const setup = scene => {
    const obd = scene.findObjectByName('Scene 1')
    console.log(scene)
    obd.position.z = -5
  }

  const onSplineMouseDown = event => {
    console.log(event)
    console.log(event.target.name)
  }

  return (
    <Suspense>
      <Spline
        scene="https://prod.spline.design/oqexVXpS59h54Vdy/scene.splinecode"
        onLoad={setup}
        onSplineMouseDown={onSplineMouseDown}
      />
    </Suspense>
  )
}

Model.propTypes = {}

export default Model
