import styled from 'styled-components'
import { mq } from '@styles/vars/media-queries.style'
import { colors } from '@styles/vars/colors.style'
import { clamp } from '@styles/utils/conversion.style'
import { breakpoints } from '@styles/vars/breakpoints.style'
import { TextBody } from '@styles/vars/textStyles.style'
import { paddingMax, paddingMin } from '@components/Container/index.style'
import { AnimateImagePosition } from '@components/animation/AnimateImage/index.style'

export const HowItWorksMain = styled.div`
  background-color: ${colors.light};

  ${mq.desk} {
    ${clamp('padding-top', 80, 100, breakpoints.desk, breakpoints.deskL)};
  }
`

export const HowItWorksSectionTitle = styled.div`
  position: relative;
`

export const HowItWorksText = styled.div`
  padding-top: 3.2rem;

  ${mq.tabletL} {
    padding-bottom: 6.4rem;
    padding-top: 0;
  }

  ${TextBody} {
    color: ${colors.darkgrey};
  }
`

export const HowItWorksVideo = styled.div`
  position: relative;

  ${AnimateImagePosition} {
    border-radius: 1.6rem;
    overflow: hidden;

    ${mq.tabletL} {
      border-radius: 1.6rem 0 0 1.6rem;
    }
  }

  video {
    height: auto;
    width: 100%;
  }

  ${mq.tabletL} {
    ${clamp(
      'margin-right',
      -paddingMin,
      -paddingMax,
      breakpoints.mobile,
      breakpoints.tabletL
    )}
  }

  ${mq.contained} {
    margin-right: calc(
      -50vw + (${breakpoints.contained / 2}px - ${paddingMax}px)
    );
  }
`
