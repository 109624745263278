import styled from 'styled-components'
import { colors } from '@styles/vars/colors.style'
import { mq } from '@styles/vars/media-queries.style'
import { Heading2, TextBody } from '@styles/vars/textStyles.style'

export const PrivacyWrapper = styled.div`
  margin-top: -0.1rem;
  background-color: ${colors.darkergrey};
  color: ${colors.light};
`

export const PrivacyContentWrapper = styled.div`
  position: relative;
`

export const PrivacyIconWrapper = styled.div`
  ${mq.tabletL} {
    transform: translateX(25%);
  }
`

export const PrivacyContent = styled.div`
  margin-top: 6.4rem;

  ${mq.tabletL} {
    margin-bottom: 6.4rem;
  }

  ${Heading2} {
    max-width: 14ch;
  }

  ${TextBody} {
    max-width: 46ch;
    color: ${colors.midgrey};
  }
`
