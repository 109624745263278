import React from 'react'
import { useInView } from 'react-intersection-observer'
import PropTypes from 'prop-types'
import { HrMain } from './index.style'
import { colors } from '@styles/vars/colors.style'

const Hr = ({
  bg = colors.light,
  delay = 0,
  rootMargin,
  triggerOnce = true,
}) => {
  const [ref, inView] = useInView({
    rootMargin: rootMargin || `0px 0px -5% 0px`,
    triggerOnce: triggerOnce,
  })

  return <HrMain ref={ref} show={inView} bg={bg} delay={delay} />
}

Hr.propTypes = {
  bg: PropTypes.string,
  delay: PropTypes.number,
}

export default Hr
