import styled from 'styled-components'
import { mq } from '@styles/vars/media-queries.style'
import { colors } from '@styles/vars/colors.style'
import { clamp } from '@styles/utils/conversion.style'
import { easings } from '@styles/vars/easings.style'
import { Heading4, TextBodySmall } from '@styles/vars/textStyles.style'
import {
  AnimateImageMain,
  AnimateImagePosition,
} from '@components/animation/AnimateImage/index.style'
import { AccordionContent } from '@components/Accordion/index.style'
import { ButtonMain } from '@components/Button/index.style'
import { GridItemMain } from '@components/GridItem/index.style'
import { AnimateSlideInWrap } from '@components/animation/AnimateSlideIn/index.style'

export const DetailsMain = styled.div`
  background-color: ${colors.light};
  padding-top: 2.4rem;

  ${mq.tabletL} {
    padding-top: 16rem;
  }

  ${GridItemMain}:last-child {
    ${mq.tabletLMax} {
      padding-top: 6.4rem;
    }
  }
`

export const DetailsImage = styled.div`
  position: relative;

  ${AnimateImageMain},
  ${AnimateImagePosition} {
    width: 100%;
  }

  ${AnimateImagePosition} {
    border-radius: 1.6rem;
    overflow: hidden;
  }
`

export const DetailsText = styled.div`
  ${clamp('padding-bottom', 16, 24)};
  margin-bottom: 3.2rem;
  position: relative;

  ${Heading4} {
    align-items: center;
    cursor: ${props => (props.interactive ? 'pointer' : 'default')};
    display: flex;
    justify-content: space-between;
    padding-bottom: 1.6rem;
    padding-top: 1.6rem;

    svg {
      transform: rotateZ(${props => (props.show ? 135 : 0)}deg);
      transition: transform 1s ${easings.inOut.default};
    }
  }

  ${TextBodySmall} {
    color: ${colors.darkgrey};
    max-width: 900px;
    width: 90%;
  }

  ${AccordionContent} ${TextBodySmall} {
    padding-top: 0.8rem;
  }

  .split__text {
    transition-delay: ${props => !props.show && `0s`};
  }

  &:last-child {
    margin-bottom: 0;
  }
`

export const DetailsNoteTrigger = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
`

export const DetailsButton = styled.div`
  ${AnimateSlideInWrap},
  ${ButtonMain} {
    width: 100%;
  }
`
