import React, { useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import gsap from 'gsap'
import { useStore, setHeader } from '@Store/index'
import ScrollSection from '@components/ScrollSection'
import Spacer from '@components/Spacer'
import Container from '@components/Container'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import PrivacyIcon from '@components/svgs/PrivacyIcon'
import SectionTitle from '@components/SectionTitle'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import { Heading2, TextBody } from '@components/TextStyles'
import {
  PrivacyWrapper,
  PrivacyContentWrapper,
  PrivacyIconWrapper,
  PrivacyContent,
} from './index.style'

const Privacy = ({ sectionTitle, title, text }) => {
  const [store, dispatch] = useStore()
  const { header } = store
  const imageRef = useRef()
  const timelineRef = useRef()
  const isTimelinePlayingRef = useRef(false)

  const [ref, inView, entry] = useInView({
    rootMargin: '0px 0px -95% 0px',
    triggerOnce: false,
  })

  useEffect(() => {
    const squares = imageRef.current.querySelectorAll('rect, path')

    timelineRef.current = gsap.timeline({
      paused: true,
    })

    timelineRef.current.fromTo(
      squares,
      {
        opacity: 0,
      },
      {
        opacity: 1,
        stagger: 0.025,
        duration: 0.5,
      }
    )
  }, [])

  useEffect(() => {
    if (!inView || isTimelinePlayingRef.current) {
      return
    }

    timelineRef.current.play()
    isTimelinePlayingRef.current = true
  }, [inView])

  useEffect(() => {
    if (!entry?.isIntersecting && entry?.boundingClientRect.top < 0) {
      setHeader(dispatch, 'dark')
    }

    if (entry?.isIntersecting) {
      setHeader(dispatch, 'light')
    }
  }, [dispatch, entry, header])

  return (
    <ScrollSection>
      <PrivacyWrapper ref={ref}>
        <Spacer size={[24, 160]} />

        <Container>
          <PrivacyContentWrapper>
            <SectionTitle title={sectionTitle} />

            <Grid>
              <GridItem
                tabletL={4}
                tabletLStart={9}
                tabletLAlign="center"
                tabletLOrder={2}
              >
                <PrivacyIconWrapper ref={imageRef}>
                  <PrivacyIcon />
                </PrivacyIconWrapper>
              </GridItem>

              <GridItem
                tabletL={6}
                tabletLStart={2}
                tabletLAlign="center"
                tabletLOrder={1}
              >
                <PrivacyContent>
                  <Heading2 as="h2">
                    <AnimateSplitText>{title}</AnimateSplitText>
                  </Heading2>

                  <Spacer size={[24, 60]} />

                  <TextBody>
                    <AnimateSplitText delay={0.1}>{text}</AnimateSplitText>
                  </TextBody>
                </PrivacyContent>
              </GridItem>
            </Grid>
          </PrivacyContentWrapper>
        </Container>

        <Spacer size={[64, 160]} />
      </PrivacyWrapper>
    </ScrollSection>
  )
}

export default Privacy
