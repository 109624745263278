import styled from 'styled-components'
import { clamp } from '@styles/utils/conversion.style'
import { colors } from '@styles/vars/colors.style'
import { TextBodySmall } from '@styles/vars/textStyles.style'
import { font } from '@styles/vars/font.style'
import { easings } from '@styles/vars/easings.style'
import { mq } from '@styles/vars/media-queries.style'

export const StatsWrapper = styled.div`
  ${clamp('padding-top', 64, 48)};
  ${clamp('padding-bottom', 64, 48)};
  background-color: ${colors.orange};
  color: ${colors.light};
`

export const StatsGrid = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

export const StatsItem = styled.div`
  position: relative;
  width: 100%;
  ${clamp('padding-top', 32, 52)};
  ${clamp('padding-bottom', 32, 52)};
  perspective: 1000px;
  text-align: center;

  ${mq.tabletL} {
    width: 25%;

    &:not(:last-child):after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      width: 0.1rem;
      height: 100%;
      background-color: ${colors.light};
      opacity: ${props => (props.show ? 0.5 : 0)};
      transform: ${props => (props.show ? 'scaleY(1)' : 'scaleY(0)')};
      transition: opacity 0.6s ${easings.inOut.default},
        transform 1s ${easings.inOut.default};
      transition-delay: ${props => `${props.index * 0.1}s`};
    }
  }

  ${TextBodySmall} {
    max-width: 16ch;
    margin: 0 auto;
  }
`

export const StatsItemStat = styled.p`
  ${clamp('font-size', 56, 72)};
  font-weight: ${font.primary.weight.light};
  opacity: ${props => (props.show ? 1 : 0)};
  transform: ${props =>
    props.show
      ? 'rotateX(0deg) translateY(0)'
      : 'rotateX(-95deg) translateY(100%)'};
  transition: opacity 1s ${easings.inOut.default},
    transform 1s ${easings.inOut.default};
  transition-delay: ${props => `${props.index * 0.1}s`};
`
