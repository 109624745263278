import React, { useRef, useEffect } from 'react'
import gsap from 'gsap'
import { useInView } from 'react-intersection-observer'
import ScrollSection from '@components/ScrollSection'
import Container from '@components/Container'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import { TextBodySmall } from '@components/TextStyles'
import {
  StatsWrapper,
  StatsItem,
  StatsGrid,
  StatsItemStat,
} from './index.style'

const Stat = ({ stat, index }) => {
  const tl = useRef()
  const wrap = useRef()
  const count = useRef()
  const currentStat = useRef({
    value: 0,
  })

  const [ref, inView] = useInView({
    rootMargin: `-15% 0px -30% 0px`,
    triggerOnce: true,
  })

  useEffect(() => {
    tl.current = gsap.timeline({
      paused: true,
      defaults: {
        duration: 2,
        ease: 'power2.inOut',
      },
    })

    tl.current.from(
      wrap.current,
      {
        xPercent: window.innerWidth > 768 ? -80 : 0,
        autoAlpha: 0,
      },
      0
    )

    tl.current.to(
      currentStat.current,
      {
        value: stat.stat,
        onUpdate: () => {
          count.current.innerHTML = Math.floor(currentStat.current.value)
            .toString()
            .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
        },
      },
      0
    )
  }, [stat])

  useEffect(() => {
    if (!inView) return

    tl.current.play()
  }, [inView])

  return (
    <StatsItem ref={ref} show={inView} index={index}>
      <StatsItemStat show={inView} index={index}>
        {stat.statPrefix ? <span>{stat.statPrefix}</span> : null}
        <span ref={count}>0</span>
      </StatsItemStat>
      <TextBodySmall>
        <AnimateSplitText delay={0.1 * index + 0.075}>
          {stat.label}
        </AnimateSplitText>
      </TextBodySmall>
    </StatsItem>
  )
}

const Stats = ({ stats }) => {
  return (
    <ScrollSection>
      <StatsWrapper>
        <Container>
          <Grid>
            <GridItem deskL={10} deskLStart={2}>
              <StatsGrid>
                {React.Children.toArray(
                  stats.map((stat, index) => <Stat stat={stat} index={index} />)
                )}
              </StatsGrid>
            </GridItem>
          </Grid>
        </Container>
      </StatsWrapper>
    </ScrollSection>
  )
}

export default Stats
