import React, { useCallback, useEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import ScrollSection from '@components/ScrollSection'
import Grid from '@components/Grid'
import GridItem from '@components/GridItem'
import Container from '@components/Container'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import AnimateImage from '@components/animation/AnimateImage'
import { animation } from '@styles/vars/animation.style'
import Spacer from '@components/Spacer'
import {
  DetailsMain,
  DetailsText,
  DetailsImage,
  DetailsButton,
  DetailsNoteTrigger,
} from './index.style'
import { Heading3, Heading4, TextBodySmall } from '@components/TextStyles'
import { GatsbyImage } from 'gatsby-plugin-image'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
import Accordion from '@components/Accordion'
import Hr from '@components/Hr'
import { colors } from '@styles/vars/colors.style'
import Button from '@components/Button'
import Plus from '@components/svgs/Plus'
import AnimateSlideIn from '@components/animation/AnimateSlideIn'
import RichText from '@components/RichText'
import Modal from '@components/Modal'
import ReleaseNotes from '../ReleaseNotes'

gsap.registerPlugin(ScrollTrigger)

const DetailsContent = ({
  heading,
  title,
  text,
  richText,
  accordion,
  accordionCallback,
  openReleaseNotes,
  open,
  openEvent,
}) => {
  return (
    <DetailsText interactive={accordion} show={open}>
      <Heading4 onClick={accordion && openEvent}>
        <AnimateSplitText delay={animation.textDelay}>
          {heading || title}
        </AnimateSplitText>
        {accordion && (
          <AnimateSlideIn delay={animation.textDelay}>
            <Plus />
          </AnimateSlideIn>
        )}
      </Heading4>

      {accordion ? (
        <Accordion open={open} callback={accordionCallback}>
          {text && (
            <TextBodySmall>
              <AnimateSplitText delay={0.2} animate={open}>
                {text}
              </AnimateSplitText>
            </TextBodySmall>
          )}
          {richText && (
            <RichText content={richText} delay={0.2} animate={open} />
          )}
        </Accordion>
      ) : text ? (
        <TextBodySmall>
          <AnimateSplitText delay={0.2} animate={open}>
            {text}
          </AnimateSplitText>
        </TextBodySmall>
      ) : richText ? (
        <RichText content={richText} />
      ) : (
        ``
      )}

      {openReleaseNotes && (
        <DetailsNoteTrigger>
          <AnimateSlideIn delay={0.4}>
            <Button
              onClick={openReleaseNotes}
              variant={'secondary'}
              size={'small'}
            >
              Release Notes
            </Button>
          </AnimateSlideIn>
        </DetailsNoteTrigger>
      )}
      <Hr bg={colors.lightgrey} />
    </DetailsText>
  )
}

const Details = ({ image, features, specs, releaseNotes, openOrder }) => {
  const wrap = useRef()
  const imageRef = useRef()
  const pinning = useRef()

  const [activeFeaturesAccordion, setActiveFeaturesAccordion] = useState(0)
  const [showReleaseNotes, setShowReleaseNotes] = useState(false)

  useEffect(() => {
    let mm = gsap.matchMedia()

    mm.add('(min-width: 1024px)', () => {
      pinning.current = ScrollTrigger.create({
        trigger: wrap.current,
        start: 'top 160px',
        end: () => {
          if (!imageRef.current) return 'bottom 160px'
          return `bottom ${imageRef.current.clientHeight + 160}px`
        },
        pin: imageRef.current,
      })
    })
  }, [])

  const refreshPin = useCallback(() => {
    if (pinning.current) {
      pinning.current.refresh()
    }
  }, [])

  return (
    <>
      {releaseNotes?.length > 0 && (
        <Modal
          isOpen={showReleaseNotes}
          setClosed={() => setShowReleaseNotes(false)}
          onDismiss={() => setShowReleaseNotes(false)}
          ariaLabel="Release Notes"
        >
          <ReleaseNotes
            releaseNotes={releaseNotes}
            close={() => setShowReleaseNotes(false)}
          />
        </Modal>
      )}
      <ScrollSection>
        <DetailsMain>
          <Container>
            <div ref={wrap}>
              <Grid>
                <GridItem tabletL={3} tabletLAlign={`flex-start`}>
                  <DetailsImage ref={imageRef}>
                    <AnimateImage>
                      <GatsbyImage
                        image={image.gatsbyImageData}
                        alt={image.description}
                      />
                    </AnimateImage>
                  </DetailsImage>
                </GridItem>
                <GridItem tabletL={8} tabletLStart={5}>
                  <Heading3>
                    <AnimateSplitText type={`lines,chars`}>
                      Features
                    </AnimateSplitText>
                  </Heading3>
                  <Spacer size={[6, 48]} />
                  {React.Children.toArray(
                    features.map((detail, index) => (
                      <DetailsContent
                        {...detail}
                        accordion
                        accordionCallback={refreshPin}
                        open={index === activeFeaturesAccordion}
                        openEvent={() => setActiveFeaturesAccordion(index)}
                      />
                    ))
                  )}
                  <Spacer size={[32, 48]} />
                  <Heading3>
                    <AnimateSplitText type={`lines,chars`}>
                      Specs
                    </AnimateSplitText>
                  </Heading3>
                  <Spacer size={[6, 48]} />
                  {React.Children.toArray(
                    specs.map((detail, index) => (
                      <DetailsContent
                        {...detail}
                        openReleaseNotes={
                          index === specs.length - 1 && releaseNotes?.length > 0
                            ? () => setShowReleaseNotes(true)
                            : null
                        }
                      />
                    ))
                  )}
                </GridItem>
              </Grid>
            </div>
            <Spacer size={[48, 80]} />
            <DetailsButton>
              <AnimateSlideIn>
                <Button onClick={openOrder} label="Order SPARQ Diagnostics">
                  <span>Order SPARQ Diagnostics</span>
                  <span>Order SPARQ Diagnostics</span>
                </Button>
              </AnimateSlideIn>
            </DetailsButton>
          </Container>
          <Spacer size={[48, 80]} />
        </DetailsMain>
      </ScrollSection>
    </>
  )
}

Details.propTypes = {
  image: PropTypes.object,
  features: PropTypes.array,
  specs: PropTypes.array,
  releaseNotes: PropTypes.array,
}

export default Details
