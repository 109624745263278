import React from 'react'
import { colors } from '../../../styles/vars/colors.style'

const PrivacyIcon = ({
  width = 342,
  height = 326,
  fill = colors.dark,
  responsive = true,
}) => {
  const svgAttributes = responsive
    ? {}
    : {
        width,
        height,
      }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 342 326"
      {...svgAttributes}
    >
      <rect
        x="97.2618"
        width="24.881"
        height="19.9048"
        rx="9.95238"
        fill="#FF4520"
      />
      <rect
        x="136.167"
        width="68.7619"
        height="19.9048"
        rx="9.95238"
        fill="#4A4A4A"
      />
      <rect
        x="218.952"
        width="25.6689"
        height="19.9048"
        rx="9.95238"
        fill="#4A4A4A"
      />

      <rect
        x="71.4762"
        y="32.119"
        width="71.9286"
        height="19.9048"
        rx="9.95238"
        fill="#4A4A4A"
      />
      <rect
        x="157.429"
        y="32.119"
        width="27.1429"
        height="19.9048"
        rx="9.95238"
        fill="#4A4A4A"
      />
      <rect
        x="198.595"
        y="32.119"
        width="71.9286"
        height="19.9048"
        rx="9.95238"
        fill="#4A4A4A"
      />

      <rect
        x="43.4285"
        y="64.6904"
        width="120.786"
        height="19.9048"
        rx="9.95238"
        fill="#4A4A4A"
      />
      <rect
        x="178.238"
        y="64.6904"
        width="120.786"
        height="19.9048"
        rx="9.95238"
        fill="#4A4A4A"
      />

      <rect
        x="26.6904"
        y="99.0718"
        width="50.6667"
        height="19.9048"
        rx="9.95238"
        fill="#4A4A4A"
      />
      <rect
        x="91.3811"
        y="99.0715"
        width="25.3333"
        height="19.9048"
        rx="9.95238"
        fill="#FF4520"
      />
      <rect
        x="130.738"
        y="99.0715"
        width="119.881"
        height="19.9048"
        rx="9.95238"
        fill="#4A4A4A"
      />
      <rect
        x="264.643"
        y="99.0715"
        width="50.6667"
        height="19.9048"
        rx="9.95238"
        fill="#4A4A4A"
      />

      <rect
        x="3.61914"
        y="131.19"
        width="160.378"
        height="19.9048"
        rx="9.95238"
        fill="#4A4A4A"
      />
      <rect
        x="178.021"
        y="131.19"
        width="160.143"
        height="19.9048"
        rx="9.95238"
        fill="#FF4520"
      />

      <rect
        y="163.762"
        width="27.5952"
        height="19.9048"
        rx="9.95238"
        fill="#FF4520"
      />
      <path
        d="M41.619 173.714C41.619 168.218 46.0749 163.762 51.5714 163.762H229.81C235.306 163.762 239.762 168.218 239.762 173.714C239.762 179.211 235.306 183.666 229.81 183.666H51.5714C46.0748 183.666 41.619 179.211 41.619 173.714Z"
        fill="#4A4A4A"
      />
      <path
        d="M253.786 173.714C253.786 168.218 258.241 163.762 263.738 163.762H332.048C337.544 163.762 342 168.218 342 173.714C342 179.211 337.544 183.666 332.048 183.666H263.738C258.241 183.666 253.786 179.211 253.786 173.714Z"
        fill="#FF4520"
      />

      <path
        d="M11.3096 209.453C11.3096 203.956 15.7654 199.5 21.262 199.5H204.476C209.973 199.5 214.429 203.956 214.429 209.453C214.429 214.949 209.973 219.405 204.476 219.405H21.262C15.7654 219.405 11.3096 214.949 11.3096 209.453Z"
        fill="#4A4A4A"
      />
      <path
        d="M228.453 209.452C228.453 203.956 232.908 199.5 238.405 199.5H320.738C326.235 199.5 330.691 203.956 330.691 209.452C330.691 214.949 326.235 219.405 320.738 219.405H238.405C232.908 219.405 228.453 214.949 228.453 209.452Z"
        fill="#4A4A4A"
      />

      <path
        d="M32.1189 245.743C32.1189 240.222 36.5944 235.747 42.1152 235.747H72.9556C78.4764 235.747 82.9519 240.222 82.9519 245.743C82.9519 251.264 78.4764 255.739 72.9556 255.739H42.1152C36.5944 255.739 32.1189 251.264 32.1189 245.743Z"
        fill="#FF4520"
      />
      <path
        d="M96.8096 245.643C96.8096 240.146 101.265 235.691 106.762 235.691H112.162C117.658 235.691 122.114 240.146 122.114 245.643C122.114 251.139 117.658 255.595 112.162 255.595H106.762C101.265 255.595 96.8096 251.139 96.8096 245.643Z"
        fill="#FF4520"
      />
      <path
        d="M136.167 245.643C136.167 240.146 140.623 235.691 146.119 235.691H260.572C266.068 235.691 270.524 240.146 270.524 245.643C270.524 251.139 266.068 255.595 260.572 255.595H146.119C140.623 255.595 136.167 251.139 136.167 245.643Z"
        fill="#4A4A4A"
      />
      <path
        d="M284.548 245.643C284.548 240.146 289.003 235.691 294.5 235.691H299.9C305.397 235.691 309.852 240.146 309.852 245.643C309.852 251.139 305.396 255.595 299.9 255.595H294.5C289.003 255.595 284.548 251.139 284.548 245.643Z"
        fill="#FF4520"
      />

      <path
        d="M43.4285 281.381C43.4285 275.885 47.8843 271.429 53.3808 271.429H154.262C159.758 271.429 164.214 275.885 164.214 281.381C164.214 286.878 159.758 291.334 154.262 291.334H53.3808C47.8843 291.334 43.4285 286.878 43.4285 281.381Z"
        fill="#4A4A4A"
      />
      <path
        d="M178.238 281.381C178.238 275.885 182.694 271.429 188.191 271.429H289.072C294.568 271.429 299.024 275.885 299.024 281.381C299.024 286.878 294.568 291.333 289.072 291.333H188.191C182.694 291.333 178.238 286.878 178.238 281.381Z"
        fill="#4A4A4A"
      />

      <path
        d="M76 315.309C76 309.813 80.4558 305.357 85.9524 305.357H133.452C138.949 305.357 143.405 309.813 143.405 315.309C143.405 320.806 138.949 325.262 133.452 325.262H85.9524C80.4558 325.262 76 320.806 76 315.309Z"
        fill="#4A4A4A"
      />
      <path
        d="M157.429 315.31C157.429 309.813 161.885 305.357 167.381 305.357H218.048C223.544 305.357 228 309.813 228 315.31C228 320.806 223.544 325.262 218.048 325.262H167.381C161.885 325.262 157.429 320.806 157.429 315.31Z"
        fill="#FF4520"
      />
      <path
        d="M242.024 315.083C242.024 309.712 246.379 305.357 251.75 305.357H256.487C261.859 305.357 266.214 309.712 266.214 315.083C266.214 320.455 261.859 324.81 256.487 324.81H251.75C246.379 324.81 242.024 320.455 242.024 315.083Z"
        fill="#4A4A4A"
      />
    </svg>
  )
}

export default PrivacyIcon
