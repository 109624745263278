import React from 'react'
import { useInView } from 'react-intersection-observer'
import { TextBodySmall } from '@components/TextStyles'
import AnimateSplitText from '@components/animation/AnimateSplitText'
import { SectionTitleWrapper } from './index.style'

const SectionTitle = ({ title }) => {
  const [ref, inView] = useInView({
    rootMargin: `-15% 0px -30% 0px`,
    triggerOnce: true,
  })

  return (
    <SectionTitleWrapper ref={ref} show={inView}>
      <TextBodySmall>
        <AnimateSplitText>{title}</AnimateSplitText>
      </TextBodySmall>
    </SectionTitleWrapper>
  )
}

export default SectionTitle
